import React, { useState } from 'react';

import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';
import { ITranslator } from '../../../types';
import ChartTooltipPopover from '../../../components/common/chartTooltipPopover';
import ConstantsHelper from '../../../helpers/ConstantsHelper';
import { sendAmplitudeEvent } from '../../../helpers/amplitude';

function LoyaltyInsulinUsageBlock({
    translate,
    tokenHeader,
    tokenInfo,
    tokenBody,
    preSeparate,
    weekOf,
}: {
    translate: ITranslator;
    tokenHeader: string;
    tokenInfo?: string;
    tokenBody?: string;
    preSeparate?: boolean;
    weekOf?: string;
}) {
    const [showPopover, setShowPopover] = useState(false);

    const logInfoExpansion = () => {
        const newState = !showPopover;

        if (newState) {
            setShowPopover(newState);
            setTimeout(() => {
                sendAmplitudeEvent(ConstantsHelper.amplitudeEventsConstants.EXPAND_INSILIN_TDI_RATIO, {
                    weekOf,
                });
            }, 3);
        }
    };

    return (
        <div className={styleGuide.header}>
            {preSeparate && <div className={styleGuide.lineAlt}></div>}

            <div className={styleGuide.loyaltyHeader}>
                <div className={styleGuide.title}>
                    {translate(tokenHeader)}

                    {tokenInfo &&
                        ChartTooltipPopover(
                            () => (
                                <span className={styleGeneral.link} onClick={logInfoExpansion}>
                                    &nbsp;<sup>&#9432;</sup>
                                </span>
                            ),
                            () => (
                                <div className={styleGeneral.main}>
                                    <div id="infoBlock" className={styleGeneral.infoBlock}>
                                        {translate(tokenInfo)}
                                    </div>
                                </div>
                            ),
                            () => setShowPopover(false)
                        )}
                </div>
            </div>

            {tokenBody && (
                <div className={styleGuide.loyaltyBody}>
                    <div className={styleGuide.text}>{translate(tokenBody)}</div>
                </div>
            )}
        </div>
    );
}

export default LoyaltyInsulinUsageBlock;
