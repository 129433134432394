import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';

import BtnPrimary from '../btnPrimary';
import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';
import { sendAmplitudeEvent } from '../../../helpers/amplitude';
import ConstantsHelper from '../../../helpers/ConstantsHelper';
import DateTimeHelper from '../../../helpers/DateTimeHelper';
import NavigationHelper from '../../../helpers/NavigationHelper';
import StringHelper from '../../../helpers/StringHelper';
import SystemHelper from '../../../helpers/SystemHelper';
import UtilityHelper from '../../../helpers/UtilityHelper';
import CheckSingle from '../checkSingle';
import { ICallback, IDateTimeFormats, IHome, ITranslator } from '../../../types';

const idBtnAgree = 'idBtnAgree';

function ContentWrapperEulaAndConfAgr({
    home,
    closeCb,
    translate,
}: {
    home: IHome;
    closeCb: ICallback<void>;
    translate: ITranslator;
}) {
    const [check1, setCheck1] = useState(false);
    const [check2, setCheck2] = useState(false);
    const amplitudePayload = useMemo(
        () => ({
            // Note:  Do not use UtilityHelper.GetDeviceClassName() to ensure the same device type is reported regardless of language selected
            deviceType: `${home.patient?.deviceClass}`,
            // Note:  Do not translate user type to ensure same set of values are reported regardless of language selected
            user: 'PWD',
            clinic: home.patient?.clinicName,
        }),
        [home.patient?.clinicName, home.patient?.deviceClass]
    );
    const pwdName = useMemo(
        () => UtilityHelper.GetFullName(home.patient?.firstName, home.patient?.lastName),
        [home.patient?.firstName, home.patient?.lastName]
    );
    const setCheck = (setterFn: any, valueToSet: boolean, eventName: string, subPayload: any) => {
        setterFn(valueToSet);
        NavigationHelper.ScrollIntoView(idBtnAgree);
        sendAmplitudeEvent(eventName, {
            ...amplitudePayload,
            ...subPayload,
        });
    };
    const cbAccept = () => {
        closeCb();
        sendAmplitudeEvent(ConstantsHelper.amplitudeEventsConstants.EULA_AND_CONF_AGR_ACCEPT, amplitudePayload);
    };
    const handleEulaAndConfAgrCaptionCb = (
        translateKey1: string,
        translateKey2: string,
        translateKey3: string,
        urlConfigKey: string,
        urlTargetName: string,
        testId: string,
        amplitudeEventName: string
    ) => (
        <span className={styleGuide.checkBoxLabel}>
            {translate(translateKey1)}&nbsp;
            <a
                data-testid={testId}
                id={testId}
                className={styleGeneral.link}
                href={SystemHelper.GetRuntimeConfig(urlConfigKey)}
                target={urlTargetName}
                onClick={() => {
                    sendAmplitudeEvent(amplitudeEventName, amplitudePayload);
                }}
            >
                {translate(translateKey2)}
            </a>
            {translate(translateKey3)}
        </span>
    );
    useEffect(() => {
        if (home?.authentication?.oktaData?.userName && amplitudePayload?.deviceType) {
            sendAmplitudeEvent(ConstantsHelper.amplitudeEventsConstants.EULA_AND_CONF_AGR_LANDING, amplitudePayload);
        }
    }, [amplitudePayload, home?.authentication?.oktaData?.userName]);

    return (
        <div className={clsx(styleGeneral.body, styleGeneral.whiteBackground)}>
            <div className={styleGuide.eulaAndConfAgr}>
                <div className={styleGuide.body}>
                    <div className={styleGuide.titleBlock}>
                        <div className={styleGuide.titleA}>
                            {translate('eulaAndConfAgr.titleA')}&nbsp;
                            <span className={styleGuide.titleBold}>{home?.patient?.physicianName ?? '--'}</span>
                            &nbsp;
                            {translate('eulaAndConfAgr.titleB')}&nbsp;
                            <span className={styleGuide.titleBold}>{home?.patient?.clinicName ?? '--'}</span>
                        </div>
                        <div className={styleGuide.titleB}>{pwdName}</div>
                        <div className={styleGuide.titleA}>
                            {translate('eulaAndConfAgr.dob', {
                                dateOfBirth: DateTimeHelper.FormatTimeStamp(
                                    home?.patient?.dateOfBirth,
                                    false,
                                    true,
                                    true,
                                    undefined,
                                    {
                                        primary: 'M/D/YYYY',
                                    } as IDateTimeFormats
                                ),
                                deviceClass: UtilityHelper.GetDeviceClassName(translate, home?.patient?.deviceClass),
                            })}
                        </div>
                    </div>
                    <div className={styleGuide.contact}>{translate('eulaAndConfAgr.contact')}</div>
                    <div className={styleGuide.need}>{translate('eulaAndConfAgr.need')}</div>
                    <div className={styleGuide.needBeforeAfter}>{translate('eulaAndConfAgr.needBefore')}</div>
                    <div className={styleGuide.checkBoxContainer}>
                        <CheckSingle
                            data-testid={'eulaAndConfAgr.chk1'}
                            id="eulaAndConfAgr.chk1"
                            checked={check1}
                            cbClickFn={(e: any) => {
                                setCheck(
                                    setCheck1,
                                    e?.checked,
                                    ConstantsHelper.amplitudeEventsConstants.EULA_AND_CONF_AGR_EULA_CHANGED,
                                    {
                                        eulaCheck: StringHelper.GetCheckBoxState(e?.checked),
                                    }
                                );
                            }}
                            captionCb={() =>
                                handleEulaAndConfAgrCaptionCb(
                                    'eulaAndConfAgr.chkEulaA',
                                    'eulaAndConfAgr.chkEulaB',
                                    'eulaAndConfAgr.chkEulaC',
                                    'REACT_APP_ABOUT_URL_LEGAL',
                                    '__EULA__',
                                    'eulaAndConfAgr.link1',
                                    ConstantsHelper.amplitudeEventsConstants.EULA_AND_CONF_AGR_EULA_LINK
                                )
                            }
                        />
                    </div>
                    <div className={styleGuide.checkBoxContainer}>
                        <CheckSingle
                            data-testid={'eulaAndConfAgr.chk2'}
                            id="eulaAndConfAgr.chk2"
                            checked={check2}
                            cbClickFn={(e: any) => {
                                setCheck(
                                    setCheck2,
                                    e?.checked,
                                    ConstantsHelper.amplitudeEventsConstants.EULA_AND_CONF_AGR_CONF_AGR_CHANGED,
                                    {
                                        confAgrCheck: StringHelper.GetCheckBoxState(e?.checked),
                                    }
                                );
                            }}
                            captionCb={() =>
                                handleEulaAndConfAgrCaptionCb(
                                    'eulaAndConfAgr.chkConfAgrA',
                                    'eulaAndConfAgr.chkConfAgrB',
                                    'eulaAndConfAgr.chkConfAgrC',
                                    'REACT_APP_ABOUT_URL_CONFIDENTIALITY',
                                    '__CONFIDENTIALITY__',
                                    'eulaAndConfAgr.link2',
                                    ConstantsHelper.amplitudeEventsConstants.EULA_AND_CONF_AGR_CONF_AGR_LINK
                                )
                            }
                        />
                    </div>
                    <div className={styleGuide.needBeforeAfter}>{translate('eulaAndConfAgr.needAfter')}</div>
                    <div className={styleGuide.parLast} id={idBtnAgree}>
                        <BtnPrimary
                            disabled={!(check1 && check2)}
                            caption={translate('eulaAndConfAgr.agree')}
                            cbClickFn={() => cbAccept()}
                            focus={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContentWrapperEulaAndConfAgr;
