import amplitude from 'amplitude-js';

export const getAmplitude = (key?: string) => amplitude.getInstance(key);

export const initAmplitude = (appKey: string, callback?: () => void) =>
    getAmplitude().init(
        appKey,
        undefined,
        {
            trackingOptions: {
                ip_address: false,
            },
        },
        callback
    );

export const sendAmplitudeEvent = (eventName: string, properties?: { [k: string]: string }) => {
    return new Promise((res) => {
        getAmplitude().logEvent(
            eventName,
            properties,
            (responseCode: number, responseBody: string, details?: { reason: string }) => {
                // Assuming all fine if there are no errors thrown
                res({
                    responseCode,
                    responseBody,
                    details,
                });
            }
        );
    });
};
