import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';

import { sendAmplitudeEvent } from '../helpers/amplitude';
import ConstantsHelper from '../helpers/ConstantsHelper';

function LoginCallback() {
    const { oktaAuth, authState } = useOktaAuth();
    const [callbackError, setCallbackError] = useState(null);

    useEffect(() => {
        oktaAuth
            .handleLoginRedirect()
            .then(() => {
                sendAmplitudeEvent(ConstantsHelper.amplitudeEventsConstants.SUCCESSFUL_LOGIN);
            })
            .catch((e) => {
                setCallbackError(e);
                sendAmplitudeEvent(ConstantsHelper.amplitudeEventsConstants.FAILED_LOGIN);
            });
    }, [oktaAuth]);

    useEffect(() => {
        if (authState?.error) {
            sendAmplitudeEvent(ConstantsHelper.amplitudeEventsConstants.FAILED_LOGIN);
        }
    }, [authState?.error]);

    const authError = authState?.error;
    const displayError = callbackError || authError;

    if (displayError) {
        return (
            <p data-testid="login-error">
                {displayError.name && displayError.message
                    ? `${displayError.name}: ${displayError.message}`
                    : `Error: ${displayError}`}
            </p>
        );
    }

    return null;
}

export default LoginCallback;
