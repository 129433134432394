import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { globalHistory } from '@reach/router';

import SystemHelper from './SystemHelper';

const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
    config: {
        instrumentationKey: SystemHelper?.GetRuntimeConfig('REACT_APP_AZURE_APP_INSIGHTS_KEY') ?? 'Dummy Key For Jest',
        maxBatchInterval: 0,
        disableFetchTracking: false,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: globalHistory },
        },
    },
});

ai.loadAppInsights();

const aiLogger = (Component: any) => withAITracking(reactPlugin, Component);

export default aiLogger;
export const aiObject = ai;
export const appInsights = ai.appInsights;
