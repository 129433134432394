/**
 * Gibraltar API for PWD
 * API for presenting Gibraltar patient data.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: azheng@insulet.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Patient info and preferences
 */
export interface Patient { 
    /**
     * Boolean indicating if disclaimer popup was see by the FE
     */
    disclaimerSeen?: boolean;
    /**
     * ISO 8601 date when patient profile was activated
     */
    firstJoined?: string;
    /**
     * The first name of patient to show in page banner.
     */
    firstName?: string;
    /**
     * The id of patient.
     */
    id?: string;
    /**
     * The first name of patient to show in page banner.
     */
    lastName?: string;
    /**
     * Class of the device that the patient uses.
     */
    deviceClass?: Patient.DeviceClassEnum;
    /**
     * Number of days of data avaialble for reporting.
     */
    reportDaysAvailable?: number;
    /**
     * Boolean indicating if welcome screen was see by the FE
     */
    welcomeSeen?: boolean;
    /**
     * Boolean indicating if EULA Consent was given
     */
    eulaAccepted?: boolean;
    /**
     * Boolean indicating if Confidentiality Agreement Consent was given
     */
    confidentialityAgreementAccepted?: boolean;
    /**
     * SMS number of the patient.
     */
    smsNumber?: string;
    /**
     * Confirmation status of the patient's SMS number.
     */
    smsNumberStatus?: Patient.SmsNumberStatusEnum;
    /**
     * ISO 8601 date when patient profile was activated
     */
    dateOfBirth?: string;
    /**
     * Assigned Clinical Worker name
     */
    physicianName?: string;
    /**
     * Assigned Clinic name
     */
    clinicName?: string;
}
export namespace Patient {
    export type DeviceClassEnum = 'omnipod5' | 'dash' | 'eros';
    export const DeviceClassEnum = {
        Omnipod5: 'omnipod5' as DeviceClassEnum,
        Dash: 'dash' as DeviceClassEnum,
        Eros: 'eros' as DeviceClassEnum
    };
    export type SmsNumberStatusEnum = 'opted_in' | 'opted_out';
    export const SmsNumberStatusEnum = {
        In: 'opted_in' as SmsNumberStatusEnum,
        Out: 'opted_out' as SmsNumberStatusEnum
    };
}