import React, { useMemo } from 'react';
import clsx from 'clsx';
import { ResponsiveBar } from '@nivo/bar';

import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';
import { IHome, IInsulinWeeklyTrendData, ITranslator } from '../../../types';
import chartLegend from '../../../assets/images/chart-legend-insulin-weekly-trends.png';
import DateTimeHelper from '../../../helpers/DateTimeHelper';
import ConstantsHelper from '../../../helpers/ConstantsHelper';
import UtilityHelper from '../../../helpers/UtilityHelper';
import {
    InsulinUsageTrendWeeklyTrend,
    TargetRelationalOperatorEqual,
    TargetRelationalOperatorGreaterThan,
    TargetRelationalOperatorGreaterThanOrEqual,
    TargetRelationalOperatorLessThan,
    TargetRelationalOperatorLessThanOrEqual,
    TargetRelationalOperatorNotEqual,
} from '../../../model/models';

let naLabel = '';

const customTick = (tick: any) => {
    return tick?.value > 0 ? (
        <g data-testId={`custom_tick--${tick.value}`} transform={`translate(${tick.x},${tick.y + 4})`}>
            <line stroke={ConstantsHelper.Chart.colorTickMark} strokeWidth={1.5} y1={0} y2={8} />
        </g>
    ) : null;
};

const CustomBarLabel = ({ bars }: any) => {
    return bars.map((bar: any) => {
        const {
            key,
            width,
            x,
            y,
            data: { id, index, value },
        } = bar;

        if (index > 0) {
            const haveValidValue = value > 0;
            let output = haveValidValue ? `${value}%` : value;
            let className = '';
            let fill = ConstantsHelper.Chart.colorTransparent;
            let xOffset = 0;

            switch (id) {
                case 'bolus': {
                    className = styleGuide.barChartLabelBolus;
                    fill = ConstantsHelper.Chart.colorBarLabelA;

                    if (!haveValidValue) {
                        output = naLabel;

                        const jointTimeInRange = bars.find((b: any) => b.data.id === 'tir' && b.data.index === index);
                        const hasNoJointTimeInRangeValue = !jointTimeInRange?.data.value;

                        if (hasNoJointTimeInRangeValue) {
                            xOffset = 11;
                        }
                    }

                    break;
                }
                case 'tir':
                    className = styleGuide.barChartLabelTir;
                    fill = ConstantsHelper.Chart.colorBarLabelB;
                    xOffset = 6;
                    break;
            }

            return output.length > 0 ? (
                <g key={key} transform={`translate(${x}, ${y})`}>
                    <text
                        transform={`translate(${width + -10 + xOffset}, ${-6})`}
                        textAnchor="middle"
                        className={className}
                        fill={fill}
                    >
                        {output}
                    </text>
                </g>
            ) : null;
        } else {
            return null;
        }
    });
};
const getChartSettings = (
    goalsBolusRatio: number,
    goalsGlucoseInRange: number,
    data: IInsulinWeeklyTrendData[],
    windowWidth: number
): any => {
    const markerHorizontalBase = {
        axis: 'y',
        legendOrientation: 'horizontal',
        legendOffsetX: windowWidth - 100,
        legendOffsetY: 0,
        lineStyle: {
            strokeWidth: 1,
        },
        textStyle: {
            fontFamily: 'OpenSans',
            fontSize: '12px',
            fontWeight: 'bold',
            start: 0,
        },
    };

    const dataCntFound = data.length;
    const dataCntExpected = 4;
    const dataCntStartIdx = dataCntExpected - dataCntFound;
    const dataEnhanced: IInsulinWeeklyTrendData[] = data?.map((d, idx) => ({
        ...d,
        index: dataCntStartIdx + idx,
    }));

    for (let idx = 0; idx < dataCntStartIdx; idx++) {
        dataEnhanced.splice(0, 0, {
            index: idx,
            frameBeg: 110,
            bolus: 0,
            tir: 0,
            frameEnd: 0,
        });
    }

    return {
        animate: true,
        areaOpacity: 0.15,
        axisBottom: {
            renderTick: customTick,
        },
        axisLeft: null,
        axisRight: null,
        axisTop: null,
        borderRadius: 8,
        colors: [
            ConstantsHelper.Chart.colorTransparent,
            ConstantsHelper.Chart.colorBolus,
            ConstantsHelper.Chart.colorTimeInRange,
            ConstantsHelper.Chart.colorTransparent,
        ],
        data: dataEnhanced,
        enableGridX: false,
        enableGridY: false,
        enableLabel: true,
        enablePointLabel: true,
        groupMode: 'grouped',
        height: 200,
        indexBy: 'index',
        innerPadding: 4,
        keys: ['frameBeg', 'bolus', 'tir', 'frameEnd'],
        label: () => <></>,
        labelSkipHeight: 0,
        labelSkipWidth: 0,
        labelTextColor: 'inherit:darker(1.4)',
        layers: [
            'grid',
            'axes',
            'bars',
            'markers',
            'legends',
            'annotations',
            (props: any) => <CustomBarLabel {...props} />,
        ],
        layout: 'vertical',
        margin: { top: 0, right: 0, bottom: 10, left: 36 },
        markers: [
            {
                ...markerHorizontalBase,
                legend: '100%',
                lineStyle: { ...markerHorizontalBase.lineStyle, stroke: 'silver' },
                textStyle: { ...markerHorizontalBase.textStyle, fill: 'black' },
                value: 100,
                legendOffsetY: 0,
            },
            {
                ...markerHorizontalBase,
                legend: `${goalsGlucoseInRange}%`,
                lineStyle: { ...markerHorizontalBase.lineStyle, stroke: ConstantsHelper.Chart.colorTimeInRange },
                textStyle: { ...markerHorizontalBase.textStyle, fill: ConstantsHelper.Chart.colorTimeInRange },
                value: goalsGlucoseInRange,
                legendOffsetY: 0,
            },
            {
                ...markerHorizontalBase,
                legend: `${goalsBolusRatio}%`,
                lineStyle: { ...markerHorizontalBase.lineStyle, stroke: ConstantsHelper.Chart.colorBolus },
                textStyle: { ...markerHorizontalBase.textStyle, fill: ConstantsHelper.Chart.colorBolus },
                value: goalsBolusRatio,
                legendOffsetY: 0,
            },
            {
                ...markerHorizontalBase,
                legend: '0%',
                lineStyle: { ...markerHorizontalBase.lineStyle, stroke: 'silver' },
                textStyle: { ...markerHorizontalBase.textStyle, fill: 'black' },
                value: 0,
                legendOffsetY: 0,
            },
        ],
        padding: 0.0,
        tooltip: () => <></>,
        width: windowWidth - 70,
        xScale: null,
        yScale: {
            type: 'linear',
            min: 0,
            max: 110,
            stacked: false,
            reverse: false,
        },
    };
};

const getFirstValue = (
    dict:
        | TargetRelationalOperatorEqual
        | TargetRelationalOperatorGreaterThan
        | TargetRelationalOperatorGreaterThanOrEqual
        | TargetRelationalOperatorLessThan
        | TargetRelationalOperatorLessThanOrEqual
        | TargetRelationalOperatorNotEqual
): number => {
    const keys = dict ? Object.keys(dict) : [];

    if (keys?.length > 0) {
        return dict[keys[0]];
    } else {
        return undefined;
    }
};

const trendLabel = (value: number | null, showUnits: boolean) => {
    const isNull = UtilityHelper.IsNull(value);
    const isNumeric = UtilityHelper.IsNumeric(value);

    return (
        <span
            className={clsx(
                styleGuide.loyaltyLabelOther,
                (isNumeric && value) || isNull ? styleGuide.loyaltyValueSmall : styleGuide.loyaltyValueNullSmall
            )}
        >
            {isNumeric ? value : naLabel}
            {isNumeric && showUnits && <span className={styleGuide.loyaltyValueUnit}>U</span>}
        </span>
    );
};

function LoyaltyInsulinUsageWeekly({ home, translate }: { home: IHome; translate: ITranslator }) {
    const weeklyTrend: InsulinUsageTrendWeeklyTrend = home?.currentInsulinUsage?.weeklyTrend ?? {
        weeks: [],
        goals: {},
    };
    const windowWidth = Math.min(home?.control?.windowSize?.width ?? 390, 390);
    const chartData: IInsulinWeeklyTrendData[] = weeklyTrend.weeks.map((s, idx) => ({
        index: idx,
        frameBeg: 110,
        bolus: s?.bolusStatistics?.ratioToInsulinTotal ?? 0,
        tir: s?.glucoseInRange ?? 0,
        frameEnd: 0,
    }));
    const goalsBolusRatio = getFirstValue(weeklyTrend.goals?.bolusRatio) ?? 0;
    const goalsGlucoseInRange = getFirstValue(weeklyTrend.goals?.glucoseInRange) ?? 0;

    naLabel = useMemo(() => translate('notAvailable'), [translate]);

    return chartData.length === 0 ? (
        <div className={styleGuide.noData}>
            {translate('nodata', { tag: translate('loyalty.insulin.weeklyTrend.title') })}
        </div>
    ) : (
        <div className={styleGuide.headerLoyalty}>
            <div className={styleGuide.loyaltyInsulinUsage}>
                <div className={clsx(styleGeneral.table, styleGuide.noGutter)}>
                    <img
                        src={chartLegend}
                        className={clsx(styleGuide.blockWidth)}
                        alt={translate('loyalty.insulin.weeklyTrend.legend')}
                    />
                </div>

                <div className={clsx(styleGeneral.table, styleGuide.loyaltyRow2)}>
                    <div className={styleGeneral.row}>
                        <span className={clsx(styleGeneral.cell, styleGuide.loyaltyBottomAlt)}>
                            <div className={styleGuide.loyaltyRow}>
                                <span className={styleGuide.loyaltyChart}>
                                    <ResponsiveBar
                                        {...getChartSettings(
                                            goalsBolusRatio,
                                            goalsGlucoseInRange,
                                            chartData,
                                            windowWidth
                                        )}
                                    />
                                </span>
                            </div>
                        </span>
                    </div>

                    <div className={styleGeneral.row}>
                        <span className={clsx(styleGeneral.cell, styleGuide.loyaltyBottomAlt)}>
                            <div className={styleGeneral.table}>
                                <div className={clsx(styleGeneral.row, styleGuide.loyaltyRow)}>
                                    <span
                                        className={clsx(
                                            styleGeneral.cell,
                                            styleGuide.loyaltyCell,
                                            styleGuide.loyaltyColLeft
                                        )}
                                    >
                                        <span className={styleGuide.loyaltyLabelLeft}>
                                            {translate('loyalty.insulin.weeklyTrend.weekOf')}:
                                        </span>
                                    </span>
                                    {weeklyTrend.weeks.map((s, idx) => (
                                        <span
                                            key={`weeklyTrend${idx}`}
                                            className={clsx(
                                                styleGeneral.cell,
                                                styleGuide.loyaltyCell,
                                                styleGuide.loyaltyColOther
                                            )}
                                        >
                                            <span className={styleGuide.loyaltyLabelOther}>
                                                {DateTimeHelper.FormatDateGlucoseTrends(
                                                    home?.dashboardBeg,
                                                    idx - weeklyTrend.weeks.length + 1
                                                )}
                                            </span>
                                        </span>
                                    ))}
                                </div>
                                <div className={clsx(styleGeneral.row, styleGuide.loyaltyRow)}>
                                    <span
                                        className={clsx(
                                            styleGeneral.cell,
                                            styleGuide.loyaltyCell,
                                            styleGuide.loyaltyColLeft
                                        )}
                                    >
                                        <span className={styleGuide.loyaltyLabelLeft}>
                                            {translate('loyalty.insulin.weeklyTrend.avgNumBolusesPerDay')}:
                                        </span>
                                    </span>
                                    {weeklyTrend.weeks.map((s, idx) => (
                                        <span
                                            key={`weeklyTrend${idx}`}
                                            className={clsx(
                                                styleGeneral.cell,
                                                styleGuide.loyaltyCell,
                                                styleGuide.loyaltyColOther
                                            )}
                                        >
                                            {trendLabel(s?.bolusStatistics?.avgNumber, false)}
                                        </span>
                                    ))}
                                </div>
                                <div className={clsx(styleGeneral.row, styleGuide.loyaltyRow)}>
                                    <span
                                        className={clsx(
                                            styleGeneral.cell,
                                            styleGuide.loyaltyCell,
                                            styleGuide.loyaltyColLeft
                                        )}
                                    >
                                        <span className={styleGuide.loyaltyLabelLeft}>
                                            {translate('loyalty.insulin.weeklyTrend.avgTotalDailyInsulin')}:
                                        </span>
                                    </span>
                                    {weeklyTrend.weeks.map((s, idx) => (
                                        <span
                                            key={`weeklyTrend${idx}`}
                                            className={clsx(
                                                styleGeneral.cell,
                                                styleGuide.loyaltyCell,
                                                styleGuide.loyaltyColOther
                                            )}
                                        >
                                            {trendLabel(s?.avgInsulinTotal, true)}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoyaltyInsulinUsageWeekly;
