import React from 'react';

import { IHome, IInsulinUsage, ITranslator } from '../../../types';
import InsightEdNotes from '../../../components/insight/InsightEdNotes';
import InsightEdWarning from '../../../components/insight/InsightEdWarning';
import UtilityHelper from '../../../helpers/UtilityHelper';

function LoyaltyInsulinUsageFactors({
    home,
    translate,
    warningsOnly,
}: {
    home: IHome;
    translate: ITranslator;
    warningsOnly?: boolean;
}) {
    const summary: IInsulinUsage = home?.currentInsulinUsage;
    const edNotes = UtilityHelper.GetEdNotes(translate, null, summary?.displayUsageExplanation?.factorsToConsider);

    return (
        <>
            {warningsOnly || <InsightEdNotes notes={edNotes} translate={translate} />}
            <InsightEdWarning notes={edNotes} translate={translate} />
        </>
    );
}

export default LoyaltyInsulinUsageFactors;
