import React from 'react';
import clsx from 'clsx';

import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';
import { IHome, IInsight3, ITranslator } from '../../../types';
import arrowByValueRange from '../../common/arrowByValueRange';
import StringHelper from '../../../helpers/StringHelper';

function InsightSummaryGlucoseTrends({
    home,
    summary,
    translate,
}: {
    home: IHome;
    summary: IInsight3;
    translate: ITranslator;
}) {
    const { prefix, delta } = StringHelper.GetDeltaPct(home, summary.timeInRangePctDelta, translate);

    return (
        <div className={clsx(styleGeneral.main, styleGuide.insightSummary)}>
            <div className={styleGuide.whiteBackground}>
                <div className={styleGuide.headerSummary}>
                    <div className={styleGuide.glucoseTrends}>
                        <div className={clsx(styleGeneral.table, styleGuide.row1)}>
                            <div className={clsx(styleGeneral.row)}>
                                <span className={clsx(styleGeneral.cell, styleGuide.left)}>
                                    <span className={clsx(styleGeneral.cell, styleGuide.top)}>
                                        <div className={styleGuide.titleAlt}>
                                            <div>{translate('glucoseTrends')}</div>
                                        </div>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className={clsx(styleGeneral.table, styleGuide.row2)}>
                            <div className={styleGeneral.row}>
                                <span className={clsx(styleGeneral.cell, styleGuide.left)}>
                                    {Math.abs(summary.timeInRangePctDelta)}%
                                </span>
                                <span className={clsx(styleGeneral.cell, styleGuide.right)}>
                                    <span className={clsx(styleGuide.top)}>
                                        <span className={styleGuide.icon}>
                                            <img
                                                src={arrowByValueRange(summary.timeInRangePctDelta)}
                                                alt="arrow icon"
                                            />
                                        </span>
                                    </span>
                                    <span className={clsx(styleGuide.bottom)}>
                                        {translate('sinceLastWeek', { tag: summary.prevWeekLabel })}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className={clsx(styleGeneral.table, styleGuide.row3)}>
                            <div className={styleGeneral.row}>
                                <span className={clsx(styleGeneral.cell, styleGuide.comparison)}>
                                    {translate('prevWeekComparisonA', {
                                        inRange: summary.timeInRangePct - summary.timeInRangePctDelta,
                                        prefix,
                                    })}
                                    <span className={styleGuide.delta}>{delta}</span>
                                    {translate('prevWeekComparisonB')}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InsightSummaryGlucoseTrends;
