import { Nav, Navbar } from 'react-bootstrap';
import { Tooltip } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import styleGeneral from '../../styles/general.module.scss';
import styleGuide from '../../styles/styleGuide.module.scss';
import iconOminpodDiscover from '../../assets/images/icon-omnipod-discover.png';
import { IBrowserInfo, ICallback, IControl, IHome, IMenuEntry, ITranslator } from '../../types';
import MenuHelper from '../../helpers/MenuHelper';

const menuLabelToShow = (entry: IMenuEntry) => {
    return (
        <span className={clsx(styleGuide.H3BlackLeft, styleGeneral.menuItem)}>
            <div className={styleGeneral.iconFrame}>
                {entry?.icon && <img src={entry.icon} alt={entry?.label} className={styleGeneral.icon} />}
            </div>
            <div className={styleGeneral.labelFrame}>{entry?.label}</div>
        </span>
    );
};
const renderBannerNotIsProd = (translate: ITranslator, notIsProd: boolean) =>
    notIsProd && <div className={styleGeneral.nonProdUse}>{translate('nonProdUse')}</div>;
const renderBannerIsUnsupportedBrowser = (translate: ITranslator, isUnsupportedBrowser: boolean) =>
    isUnsupportedBrowser && <div className={styleGeneral.unsupportedBrowser}>{translate('unsupportedBrowser')}</div>;

function Menu({
    home,
    menuEntries,
    isMenuOpen,
    menuOpenCloseCb,
    translate,
}: {
    home: IHome;
    menuEntries: IMenuEntry[];
    isMenuOpen: boolean;
    menuOpenCloseCb: ICallback<void>;
    translate: ITranslator;
}) {
    const control = home.control ?? ({} as IControl);
    const browserInfo = control?.browserInfo ?? ({} as IBrowserInfo);
    const notIsProd = !control.isProd;
    const isUnsupportedBrowser = !browserInfo.supported;

    return (
        <div className={styleGeneral.main}>
            {renderBannerNotIsProd(translate, notIsProd)}
            {renderBannerIsUnsupportedBrowser(translate, isUnsupportedBrowser)}
            <Navbar
                className={clsx(styleGeneral.navbarBox, 'navbar', 'navbar-light', 'bg-light')}
                collapseOnSelect
                expand={false}
                bg="light"
                variant="light"
            >
                <Tooltip title={translate('goToHomeScreen')}>
                    <Navbar.Brand
                        href="/"
                        className={clsx(styleGeneral.navbarBrand, styleGuide.banner)}
                        onClick={() => menuOpenCloseCb({ isMenuOpen: false, menuEntry: MenuHelper.MenuEntryHome })}
                    >
                        <img className={styleGeneral.navbarLogo} src={iconOminpodDiscover} alt="Omnipod Discover™" />
                    </Navbar.Brand>
                </Tooltip>
                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    className={clsx(styleGeneral.navbarHamburger, styleGeneral.layerTopmost)}
                    onClick={() => menuOpenCloseCb({ isMenuOpen: !isMenuOpen })}
                >
                    <div className={styleGeneral.menu}>
                        <div className={clsx(styleGeneral.bar1, isMenuOpen && styleGeneral.bar1Change)}></div>
                        <div className={clsx(styleGeneral.bar2, isMenuOpen && styleGeneral.bar2Change)}></div>
                        <div className={clsx(styleGeneral.bar3, isMenuOpen && styleGeneral.bar3Change)}></div>
                    </div>
                </Navbar.Toggle>
                <Navbar.Collapse
                    id="responsive-navbar-nav"
                    className={clsx(styleGeneral.layerTopmost, styleGeneral.scrollable, styleGeneral.menuFrame)}
                >
                    <Nav className="mr-auto" data-testid="main-menu">
                        {menuEntries
                            ?.filter((menuEntry) => menuEntry.label?.length > 0)
                            ?.map((menuEntry, idx: number) => (
                                <span key={`navSpan${idx}`}>
                                    {menuEntry?.preSeparate && idx > 0 && (
                                        <span key={`navSpanT${idx}`} className={styleGeneral.menuItemPreSeparate} />
                                    )}
                                    <Nav.Link
                                        data-testid={menuEntry?.label?.replace(' ', '_')}
                                        key={`navRow${idx}`}
                                        eventKey={`navLink${idx}`}
                                        href={menuEntry.path}
                                        onClick={() => menuOpenCloseCb({ isMenuOpen: false, menuEntry: menuEntry })}
                                    >
                                        {menuLabelToShow(menuEntry)}
                                    </Nav.Link>
                                    {menuEntry?.postSeparate && (
                                        <span key={`navSpanB${idx}`} className={styleGeneral.menuItemPreSeparate} />
                                    )}
                                </span>
                            ))}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
}

export default Menu;
