import React from 'react';
import clsx from 'clsx';

import BtnPrimary from '../btnPrimary';
import UiHelper from '../../../helpers/UiHelper';
import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';
import iconOpenArmBoy from '../../../assets/images/boy-open-arm.svg';
import { IBrowserInfo, ICallback, IControl, IHome, ITranslator } from '../../../types';

function ContentWrapperNotFound({
    home,
    clearErrorCb,
    translate,
}: {
    home: IHome;
    clearErrorCb: ICallback<void>;
    translate: ITranslator;
}) {
    const control = home.control ?? ({} as IControl);
    const browserInfo = control.browserInfo ?? ({} as IBrowserInfo);

    return (
        <>
            <div
                className={clsx(
                    styleGeneral.layerTopmostLess1,
                    UiHelper.GetBannerSpacingClasses(
                        control.isProd,
                        browserInfo.supported,
                        styleGuide.modalBackdrop,
                        styleGuide.modalBackdropOneBanner,
                        styleGuide.modalBackdropTwoBanners
                    )
                )}
            />
            <div
                className={clsx(
                    styleGeneral.errorPage,
                    styleGeneral.errorPageTest,
                    styleGeneral.layerTopmostLess1,
                    styleGuide.notFound,
                    'errorPageAnimation'
                )}
            >
                <div className={styleGuide.image}>
                    <img src={iconOpenArmBoy} alt={translate('notFound.alt')} />
                </div>
                <div className={styleGuide.ohNo}>{translate('notFound.title')}</div>
                <div className={styleGuide.cantFind}>{translate('notFound.subTitle')}</div>
                <div>
                    <BtnPrimary caption={translate('notFound.buttonText')} cbClickFn={clearErrorCb} />
                </div>
            </div>
        </>
    );
}

export default ContentWrapperNotFound;
