// eslint-disable-next-line import/named
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import { reducer } from '../store/root-reducer';
import { IHome } from '../types';

export default configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger).concat(thunk),
});

export const useTypedSelector: TypedUseSelectorHook<IHome> = useSelector;
