import React, { ReactNode, useEffect } from 'react';
import clsx from 'clsx';
import { Redirect } from 'react-router';
import { useOktaAuth } from '@okta/okta-react';

import { Patient } from 'src/model/patient';

import ContentWrapperEnrollmentPending from './ContentWrapperEnrollmentPending';
import ContentWrapperEnrollmentRevoked from './ContentWrapperEnrollmentRevoked';
import ContentWrapperSmsNumber from './ContentWrapperSmsNumber';
import ContentWrapperNotFound from './ContentWrapperNotFound';
import ContentWrapperDisclaimer from './ContentWrapperDisclaimer';
import ContentWrapperWelcome from './ContentWrapperWelcome';
import ContentWrapperEulaAndConfAgr from './ContentWrapperEulaAndConfAgr';

import { IControl, IError, IHome, IObjectHash, IPatient, ITranslator } from '../../../types';
import UiHelper from '../../../helpers/UiHelper';
import { updateProfile, saveAndConfirmSmsNumber, pollProfileActionCreator } from '../../../store/home';
import styleGeneral from '../../../styles/general.module.scss';
import SystemHelper from '../../../helpers/SystemHelper';

const pickComponent = ({
    showError,
    showRevoked,
    showPending,
    showWelcome,
    showDisclaimer,
    showEulaAndConfAgr,
    showVerifySmsNumber,
    home,
    clearErrorCb,
    podderCentralCb,
    welcomeSeenCb,
    disclaimerSeenCb,
    eulaAndConfAgrCb,
    verifySmsNumberCb,
    confirmedSmsNumberCb,
    children,
    translate,
}: {
    showError: boolean;
    showRevoked: boolean;
    showPending: boolean;
    showWelcome: boolean;
    showDisclaimer: boolean;
    showEulaAndConfAgr: boolean;
    showVerifySmsNumber: boolean;
    home: IHome;
    clearErrorCb: any;
    podderCentralCb: any;
    welcomeSeenCb: any;
    disclaimerSeenCb: any;
    eulaAndConfAgrCb: any;
    verifySmsNumberCb: any;
    confirmedSmsNumberCb: any;
    children: any;
    translate: ITranslator;
}) => {
    if (home?.control?.reloadInProgress) {
        return null;
    } else if (showError) {
        return <ContentWrapperNotFound home={home} clearErrorCb={clearErrorCb} translate={translate} />;
    } else if (showEulaAndConfAgr) {
        return <ContentWrapperEulaAndConfAgr home={home} closeCb={eulaAndConfAgrCb} translate={translate} />;
    } else if (showVerifySmsNumber) {
        return (
            <ContentWrapperSmsNumber
                home={home}
                verifyCb={verifySmsNumberCb}
                confirmedCb={confirmedSmsNumberCb}
                translate={translate}
            />
        );
    } else if (showRevoked) {
        return <ContentWrapperEnrollmentRevoked podderCentralCb={podderCentralCb} translate={translate} />;
    } else if (showPending) {
        return <ContentWrapperEnrollmentPending podderCentralCb={podderCentralCb} translate={translate} />;
    } else if (showWelcome) {
        return <ContentWrapperWelcome home={home} closeCb={welcomeSeenCb} translate={translate} />;
    } else {
        return (
            <>
                {showDisclaimer && (
                    <ContentWrapperDisclaimer home={home} closeCb={disclaimerSeenCb} translate={translate} />
                )}
                {children}
            </>
        );
    }
};

function ContentWrapperAll({
    home,
    dispatch,
    showIfInvalidEnrollment,
    children,
    translate,
}: {
    home: IHome;
    dispatch: any;
    showIfInvalidEnrollment: boolean;
    children?: ReactNode;
    translate: ITranslator;
}) {
    const control: IControl = home.control ?? ({} as IControl);
    const patient: IPatient = home.patient ?? ({} as IPatient);
    const error: IObjectHash<IError> = home.errors ?? ({} as IObjectHash<IError>);
    const podderCentralCb = () =>
        window.location.assign(SystemHelper?.GetRuntimeConfig('REACT_APP_PODDER_CENTRAL_URL_LINK'));
    const clearErrorCb = () => SystemHelper.DismissError(dispatch);
    const disclaimerSeenCb = () => dispatch(updateProfile({ disclaimerSeen: true }));
    const eulaAndConfAgrCb = () =>
        dispatch(updateProfile({ eulaAccepted: true, confidentialityAgreementAccepted: true }));
    const welcomeSeenCb = () => {
        dispatch(updateProfile({ welcomeSeen: true }));

        return <Redirect to="/" />;
    };
    const verifySmsNumberCb = (smsNumber: string) => {
        dispatch(saveAndConfirmSmsNumber({ smsNumber }));
        dispatch(pollProfileActionCreator({ active: true }));
        return <Redirect to="/" />;
    };

    const confirmedSmsNumberCb = () => {
        dispatch(pollProfileActionCreator({ active: false }));
        return <Redirect to="/" />;
    };

    const oktaAuthState = useOktaAuth()?.authState;
    const pauseItEnabled = home.control.pauseItEnabled;

    useEffect(() => {
        if (!patient.isEnrolled) {
            const oktaData = SystemHelper.ParseOktaData(oktaAuthState, pauseItEnabled);

            UiHelper.FetchPatient(dispatch, UiHelper.SaveOktaData(dispatch, oktaData));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patient.isEnrolled]);

    const showError = UiHelper.HaveError(home) && !error.silent;
    const showRevoked = patient.isUnenrolled && !showIfInvalidEnrollment;
    const showPending = !patient.isUnenrolled && !patient.isEnrolled && !showIfInvalidEnrollment;
    const showWelcomeDisclaimer = !patient.isUnenrolled && patient.isEnrolled && !showIfInvalidEnrollment;
    const showWelcome = !patient.welcomeSeen && showWelcomeDisclaimer;
    const showDisclaimer = !patient.disclaimerSeen && patient.welcomeSeen && showWelcomeDisclaimer;
    const showEulaAndConfAgr =
        control.pauseItEnabled && !(patient.eulaAccepted && patient.confidentialityAgreementAccepted);
    const showVerifySmsNumber =
        control.pauseItEnabled &&
        !patient.smsNumberStatus &&
        patient.eulaAccepted &&
        patient.confidentialityAgreementAccepted &&
        patient.deviceClass === Patient.DeviceClassEnum.Omnipod5;

    return UiHelper.IsSiteReadyToGo(home) ? (
        pickComponent({
            showError,
            showRevoked,
            showPending,
            showWelcome,
            showDisclaimer,
            showEulaAndConfAgr,
            showVerifySmsNumber,
            home,
            clearErrorCb,
            podderCentralCb,
            welcomeSeenCb,
            disclaimerSeenCb,
            eulaAndConfAgrCb,
            verifySmsNumberCb,
            confirmedSmsNumberCb,
            children,
            translate,
        })
    ) : (
        <div className={styleGeneral.main} data-testid="content_wrapper_all">
            <div className={clsx(styleGeneral.body, styleGeneral.whiteBackground)} />
        </div>
    );
}

export default ContentWrapperAll;
