import React from 'react';

import BtnSingle from '../common/btnSingle';
import styleGuide from '../../styles/styleGuide.module.scss';
import { FeedbackValue } from '../../model/models';
import { IHome, IInsight, IInsightFeedback, ITranslator } from '../../types';
import { sendAmplitudeEvent } from '../../helpers/amplitude';
import ConstantsHelper from '../../helpers/ConstantsHelper';

function InsightUserFeedback({
    home,
    cbClickFeedbackFn,
    insight,
    translate,
}: {
    home: IHome;
    cbClickFeedbackFn: any;
    insight: IInsight;
    translate: ITranslator;
}) {
    const cbClickFn = (feedbackValue: string | null) => {
        if (insight) {
            const insightIds: string[] = insight.childInsightIds ?? [insight.insightId];
            const insightIdParent = insight.childInsightIds ? insight.insightId : null;
            const feedback =
                feedbackValue === FeedbackValue.FeedbackValueEnum._1
                    ? ConstantsHelper.amplitudeEventsConstants.FEEDBACK_VALUE_TRUE
                    : ConstantsHelper.amplitudeEventsConstants.FEEDBACK_VALUE_FALSE;

            insightIds.forEach((insightId, idx) => {
                setTimeout(() => {
                    sendAmplitudeEvent(ConstantsHelper.amplitudeEventsConstants.CLICK_INSIGHT_FEEDBACK, {
                        insightId,
                        insightType: insight.insightType,
                        patternType: insight.patternType,
                        feedback,
                    });
                    cbClickFeedbackFn({
                        insightId,
                        feedbackValue,
                        insightIdParent,
                    } as IInsightFeedback);
                }, idx * 150); // Stagger additional feedback calls so prevent network flooding
            });
        }
    };

    return (
        <div className={styleGuide.feedback}>
            <div className={styleGuide.whiteBackground}>
                <span className={styleGuide.main}>{translate('wasThisInsightUseful')}</span>
                <span className={styleGuide.btnGroup}>
                    <BtnSingle
                        isActive={insight?.feedbackValue === FeedbackValue.FeedbackValueEnum._1}
                        caption={translate('yes')}
                        cbClickFn={() => cbClickFn(FeedbackValue.FeedbackValueEnum._1)}
                    />
                    <BtnSingle
                        isActive={insight?.feedbackValue === FeedbackValue.FeedbackValueEnum._2}
                        caption={translate('no')}
                        cbClickFn={() => cbClickFn(FeedbackValue.FeedbackValueEnum._2)}
                    />
                </span>
                <div className={styleGuide.message}>{home?.control?.message}</div>
            </div>
        </div>
    );
}

export default InsightUserFeedback;
